import React from 'react';
import SubLayout from '../../../components/Layout/SubLayout.js';
import strings from "../../../components/Language/index.js";

import "./../tradebot.scss";

import { Form, Input, Button } from 'antd';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from '../../../components/config/api.js';
import { getToken, storeUserData } from '../../../components/Util/index.js';
import _ from 'lodash';

class SettingScreen extends React.Component {
    constructor(props) {
        super(props);
        var params_id = this.props.match.params.id;
        this.state = {
            loading: false,
            params_id: params_id,
            tabValue: "Binance",
            access_token: "",
            close_all_trades: 0,
            discontinue_profit: 0,
            amount_double: 0,
            time_frame: 0,
            is_instant_buy: 0,
            line: 0,
            data: [],
            is_load: false,
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            setTimeout(() => {
                this.setState({ access_token: token });
                this.getData();
            });
        }
    }

    getData() {
        var self = this;
        if (self.state.access_token) {

            axios({
                method: 'GET',
                url: api.botb + this.state.params_id + "/detail",
                headers: {
                    'Authorization': 'Bearer ' + self.state.access_token,
                    'Content-Type': 'application/json'
                }
            }).then(function (ress) {
                if (ress.data && ress.data.error === 200) {

                    self.setState({
                        data: ress.data.data,
                        strategy_period: (ress.data.data && ress.data.data.type) ? 1 : 0,
                        is_load: true,
                        close_all_trades: (ress.data.data && ress.data.data.close_all_trades == true) ? 1 : 0,
                        discontinue_profit: (ress.data.data && ress.data.data.discontinue_profit == true) ? 1 : 0,
                        entry_gap_percentage: (ress.data.data && ress.data.data.entry_gap_percentage) ? ress.data.data.entry_gap_percentage : 0,
                        line: (ress.data.data && ress.data.data.line) ? ress.data.data.line : 0,
                        amount_double: (ress.data.data && ress.data.data.amount_double == true) ? 1 : 0,
                        amount: (ress.data.data && ress.data.data.amount) ? ress.data.data.amount : 0,
                        take_profit_percentage: (ress.data.data && ress.data.data.take_profit_percentage) ? ress.data.data.take_profit_percentage : 0,
                        multiply: (ress.data.data && ress.data.data.multiply) ? ress.data.data.multiply : 0,
                        is_instant_buy: (ress.data.data && ress.data.data.is_instant_buy == true) ? 1 : 0,
                        time_frame: (ress.data.data && ress.data.data.time_frame) ? ress.data.data.time_frame : 0,
                        trailing_stop: (ress.data.data && ress.data.data.trailing_stop) ? ress.data.data.trailing_stop : 0,
                        max_currency: (ress.data.data && ress.data.data.max_currency) ? ress.data.data.max_currency : 0,
                        bollinger_period: (ress.data.data && ress.data.data.bollinger_period) ? ress.data.data.bollinger_period : 0,
                        bollinger_multiplier: (ress.data.data && ress.data.data.bollinger_multiplier) ? ress.data.data.bollinger_multiplier : 0,
                        max_trades: (ress.data.data && ress.data.data.max_trades) ? ress.data.data.max_trades : 0,
                    });
                } else {
                    self.setState({
                        is_load: true,
                    });
                    if (ress.data.validation && ress.data.validation.length !== 0) {

                        _.forEach(ress.data.validation, function (value) {
                            if (value[0]) {
                                toast.error(value[0]);
                            }
                        });
                    } else {
                        toast.error(ress.data.message);
                    }
                }
            }).catch(function (e) {
                //toast.error(e.message);
            });
        }
    };

    onFinish(values) {
        var self = this;
        values.close_all_trades = this.state.close_all_trades;
        values.discontinue_profit = this.state.discontinue_profit;
        values.line = this.state.line;
        values.amount_double = this.state.amount_double;
        values.time_frame = this.state.time_frame;
        values.is_instant_buy = this.state.is_instant_buy;
        values.amount = parseFloat(values.amount);
        values.amount = parseFloat(values.amount);
        values.max_currency = values.max_currency ? values.max_currency : 1;

        var API = api.botb + this.state.params_id + "/trade-settings";
        console.log("API:::::", API);
        console.log("values:::::", values);

        self.setState({ loading: true });
        axios({
            method: 'POST',
            url: API,
            data: values,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {

            if (ress.data && ress.data.error === 200) {
                toast.success(ress.data.message);
                self.getData();
            } else {
                if (ress.data.validation && ress.data.validation.length !== 0) {
                    _.forEach(ress.data.validation, function (value) {
                        if (value[0]) {
                            toast.error(value[0]);
                        }
                    });
                } else {
                    toast.error(ress.data.message);
                }
            }
            self.setState({ loading: false });
        }).catch(function (e) {
            //toast.error(e.message);
            self.setState({ loading: false });
        });
    };

    handleChange = (event) => {
        this.setState({ strategy_period: event.target.value })
    };


    render() {
        return (
            <SubLayout data={{ "title": strings.trade_settings }}>
                {this.state.is_load ?
                    <Form
                        className='offset-md-3 col-md-6 trad_bot'
                        name="sign-in"
                        onFinish={(data) => this.onFinish(data)}
                        initialValues={{
                            close_all_trades: (this.state.data && this.state.data.close_all_trades) ? this.state.data.close_all_trades : 0,
                            discontinue_profit: (this.state.data && this.state.data.discontinue_profit) ? this.state.data.discontinue_profit : 0,
                            entry_gap_percentage: (this.state.data && this.state.data.entry_gap_percentage) ? this.state.data.entry_gap_percentage : 0,
                            line: (this.state.data && this.state.data.line) ? this.state.data.line : 0,
                            amount_double: (this.state.data && this.state.data.amount_double == true) ? 1 : 0,
                            amount: (this.state.data && this.state.data.amount) ? this.state.data.amount : 0,
                            take_profit_percentage: (this.state.data && this.state.data.take_profit_percentage) ? this.state.data.take_profit_percentage : 0,
                            multiply: (this.state.data && this.state.data.multiply) ? this.state.data.multiply : 0,
                            // trailing: (this.state.data && this.state.data.trailing) ? this.state.data.trailing : 0,
                            is_instant_buy: (this.state.data && this.state.data.is_instant_buy) ? this.state.data.is_instant_buy : 0,
                            time_frame: (this.state.data && this.state.data.time_frame) ? this.state.data.time_frame : 0,
                            trailing_stop: (this.state.data && this.state.data.trailing_stop) ? this.state.data.trailing_stop : 0,
                            max_currency: (this.state.data && this.state.data.max_currency) ? this.state.data.max_currency : 0,
                            bollinger_period: (this.state.data && this.state.data.bollinger_period) ? this.state.data.bollinger_period : 0,
                            bollinger_multiplier: (this.state.data && this.state.data.bollinger_multiplier) ? this.state.data.bollinger_multiplier : 0,
                            max_trades: (this.state.data && this.state.data.max_trades) ? this.state.data.max_trades : 0,
                        }}
                    >
                        <div className="list_item" style={{ padding: 0, paddingBlock: 10 }}>
                            <div className='row' style={{ margin: 10, marginTop: 20 }}>
                                <div className='col-6'>
                                    <label className='mb-2' style={{ color: "#FFFFFF", fontSize: 14 }}>{"Close all trades"}</label>
                                    <Select
                                        value={this.state.close_all_trades}
                                        name="close_all_trades"
                                        style={{ backgroundColor: "transparent", color: "#FFFFFF", display: "flex", alignItems: "center", justifyContent: "space-between" }}
                                        label="close_all_trades"
                                        onChange={(e) => this.setState({ close_all_trades: e.target.value })}
                                    >
                                        <MenuItem value={0}>{"No"}</MenuItem>
                                        <MenuItem value={1}>{"Yes"}</MenuItem>
                                    </Select>
                                </div>
                                <div className='col-6'>
                                    <label className='mb-2' style={{ color: "#FFFFFF", fontSize: 14 }}>{"Discontinue Profit"}</label>
                                    <Select
                                        value={this.state.discontinue_profit}
                                        name="discontinue_profit"
                                        style={{ backgroundColor: "transparent", color: "#FFFFFF", display: "flex", alignItems: "center", justifyContent: "space-between" }}
                                        label="discontinue_profit"
                                        onChange={(e) => this.setState({ discontinue_profit: e.target.value })}
                                    >
                                        <MenuItem value={0}>{"No"}</MenuItem>
                                        <MenuItem value={1}>{"Yes"}</MenuItem>
                                    </Select>
                                </div>
                            </div>
                            <div className='row' style={{ margin: 10, marginTop: 20 }}>
                                <div className='col-6'>
                                    <label className='mb-2' style={{ color: "#FFFFFF", fontSize: 14 }}>{"Entry Gap(%)"}</label>
                                    <Form.Item
                                        className='trade-form-input'
                                        name="entry_gap_percentage"
                                    >
                                        <Input className="form-control rounded-xs" />
                                    </Form.Item>
                                </div>
                                <div className='col-6'>
                                    <label className='mb-2' style={{ color: "#FFFFFF", fontSize: 14 }}>{"Line"}</label>
                                    <Select
                                        value={this.state.line}
                                        name="line"
                                        style={{ backgroundColor: "transparent", color: "#FFFFFF", display: "flex", alignItems: "center", justifyContent: "space-between" }}
                                        label="line"
                                        onChange={(e) => this.setState({ line: e.target.value })}
                                    >
                                        <MenuItem value={0}>{"Upper"}</MenuItem>
                                        <MenuItem value={1}>{"Middle"}</MenuItem>
                                        <MenuItem value={2}>{"Lower"}</MenuItem>
                                    </Select>
                                </div>
                            </div>
                            <div className='row' style={{ margin: 10, marginTop: 20 }}>
                                <div className='col-6'>
                                    <label className='mb-2' style={{ color: "#FFFFFF", fontSize: 14 }}>{"Amount Double"}</label>
                                    <Select
                                        value={this.state.amount_double}
                                        name="amount_double"
                                        style={{ backgroundColor: "transparent", color: "#FFFFFF", display: "flex", alignItems: "center", justifyContent: "space-between" }}
                                        label="amount_double"
                                        onChange={(e) => this.setState({ amount_double: e.target.value })}
                                    >
                                        <MenuItem value={0}>{"No"}</MenuItem>
                                        <MenuItem value={1}>{"Yes"}</MenuItem>
                                    </Select>
                                </div>
                                <div className='col-6'>
                                    <label className='mb-2' style={{ color: "#FFFFFF", fontSize: 14 }}>{"Amount"}</label>
                                    <Form.Item
                                        className='trade-form-input'
                                        name="amount"
                                    >
                                        <Input className="form-control rounded-xs" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='row' style={{ margin: 10, marginTop: 20 }}>
                                <div className='col-6'>
                                    <label className='mb-2' style={{ color: "#FFFFFF", fontSize: 14 }}>{"Take Profit (%)"}</label>
                                    <Form.Item
                                        className='trade-form-input'
                                        name="take_profit_percentage"
                                    >
                                        <Input className="form-control rounded-xs" />
                                    </Form.Item>
                                </div>
                                <div className='col-6'>
                                    <label className='mb-2' style={{ color: "#FFFFFF", fontSize: 14 }}>{"Multiply"}</label>
                                    <Form.Item
                                        className='trade-form-input'
                                        name="multiply"
                                    >
                                        <Input className="form-control rounded-xs" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='row' style={{ margin: 10, marginTop: 20 }}>
                                <div className='col-6'>
                                    <label className='mb-2' style={{ color: "#FFFFFF", fontSize: 14 }}>{"Instant Buy"}</label>
                                    <Select
                                        value={this.state.is_instant_buy}
                                        name="is_instant_buy"
                                        style={{ backgroundColor: "transparent", color: "#FFFFFF", display: "flex", alignItems: "center", justifyContent: "space-between" }}
                                        label="is_instant_buy"
                                        onChange={(e) => this.setState({ is_instant_buy: e.target.value })}
                                    >
                                        <MenuItem value={0}>{"No"}</MenuItem>
                                        <MenuItem value={1}>{"Yes"}</MenuItem>
                                    </Select>
                                </div>
                                <div className='col-6'>
                                    <label className='mb-2' style={{ color: "#FFFFFF", fontSize: 14 }}>{"Time Frame"}</label>
                                    <Select
                                        value={this.state.time_frame}
                                        name="c"
                                        style={{ backgroundColor: "transparent", color: "#FFFFFF", display: "flex", alignItems: "center", justifyContent: "space-between" }}
                                        label="time_frame"
                                        onChange={(e) => this.setState({ time_frame: e.target.value })}
                                    >
                                        <MenuItem value={0}>{"1 Minute"}</MenuItem>
                                        <MenuItem value={1}>{"5 Minute"}</MenuItem>
                                        <MenuItem value={2}>{"15 Minute"}</MenuItem>
                                        <MenuItem value={3}>{"30 Minute"}</MenuItem>
                                        <MenuItem value={4}>{"1 Hour"}</MenuItem>
                                        <MenuItem value={5}>{"4 Hour"}</MenuItem>
                                        <MenuItem value={6}>{"1 Day"}</MenuItem>
                                        <MenuItem value={7}>{"1 Week"}</MenuItem>
                                        <MenuItem value={8}>{"1 Month"}</MenuItem>
                                    </Select>
                                </div>
                            </div>
                            <div className='row' style={{ margin: 10, marginTop: 20 }}>
                                <div className='col-6'>
                                    <label className='mb-2' style={{ color: "#FFFFFF", fontSize: 14 }}>{"Trailing Stop"}</label>
                                    <Form.Item
                                        className='trade-form-input'
                                        name="trailing_stop"
                                    >
                                        <Input className="form-control rounded-xs" />
                                    </Form.Item>
                                </div>
                                {/* <div className='col-6'>
                                    <label className='mb-2' style={{ color: "#FFFFFF", fontSize: 14 }}>{"Max Currency"}</label>
                                    <Form.Item
                                        className='trade-form-input'
                                        name="max_currency"
                                    >
                                        <Input className="form-control rounded-xs" />
                                    </Form.Item>
                                </div> */}
                            </div>
                            <div className='row' style={{ margin: 10, marginTop: 20 }}>
                                <div className='col-6'>
                                    <label className='mb-2' style={{ color: "#FFFFFF", fontSize: 14 }}>{"Bollinger Period"}</label>
                                    <Form.Item
                                        className='trade-form-input'
                                        name="bollinger_period"
                                    >
                                        <Input className="form-control rounded-xs" />
                                    </Form.Item>
                                </div>
                                <div className='col-6'>
                                    <label className='mb-2' style={{ color: "#FFFFFF", fontSize: 14 }}>{"Max Trades"}</label>
                                    <Form.Item
                                        className='trade-form-input'
                                        name="max_trades"
                                    >
                                        <Input className="form-control rounded-xs" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='row' style={{ margin: 10, marginTop: 20 }}>
                                <div className='col-6'>
                                    <label className='mb-2' style={{ color: "#FFFFFF", fontSize: 14 }}>{"Bollinger Multiplier"}</label>
                                    <Form.Item
                                        className='trade-form-input'
                                        name="bollinger_multiplier"
                                    >
                                        <Input className="form-control rounded-xs" />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>

                        <div style={{ padding: 0, margin: 10 }}>
                            <Form.Item>
                                <Button
                                    loading={this.state.loading}
                                    htmlType="submit"
                                    className='btn rounded-sm btn-m login_button text-uppercase font-700 mt-4 mb-3 btn-full '
                                    style={{ opacity: this.state.loading ? 0.7 : 1, fontSize: 14, paddingBlock: 10, background: '#5CBC7C', color: "#FFF", width: "100%", borderRadius: 10 }}
                                >
                                    {this.state.loading ? strings.loading : strings.save}
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                    : null}
            </SubLayout >
        );
    }
}

export default SettingScreen;