import React from 'react';
import { Link } from 'react-router-dom';
import SubLayout from '../../../components/Layout/SubLayout.js';
import strings from "../../../components/Language/index.js";
import Switch from '@mui/material/Switch';

import ArrowForWord from './../../../assets/images/icons/arrow_forword.svg';

import "./../tradebot.scss";

import { Form, Input, Button } from 'antd';

import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from '../../../components/config/api.js';
import { getToken, storeUserData } from '../../../components/Util/index.js';
import _ from 'lodash';

class SettingScreen extends React.Component {
    constructor(props) {
        super(props);
        var params_id = this.props.match.params.id;
        this.state = {
            loading: false,
            params_id: params_id,
            tabValue: "Binance",
            access_token: "",
            data: [],
            is_load: false,
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            setTimeout(() => {
                this.setState({ access_token: token });
                this.getData();
            });
        }
    }

    getData() {
        var self = this;
        if (self.state.access_token) {

            axios({
                method: 'GET',
                url: api.bot + this.state.params_id + "/detail",
                headers: {
                    'Authorization': 'Bearer ' + self.state.access_token,
                    'Content-Type': 'application/json'
                }
            }).then(function (ress) {
                if (ress.data && ress.data.error === 200) {

                    self.setState({
                        data: ress.data.data,
                        is_load: true,
                    });
                } else {
                    self.setState({
                        is_load: true,
                    });
                    if (ress.data.validation && ress.data.validation.length !== 0) {

                        _.forEach(ress.data.validation, function (value) {
                            if (value[0]) {
                                toast.error(value[0]);
                            }
                        });
                    } else {
                        toast.error(ress.data.message);
                    }
                }
            }).catch(function (e) {
                //toast.error(e.message);
            });
        }
    };

    onFinish(values) {
        var self = this;
        self.setState({ loading: true });
        axios({
            method: 'POST',
            url: api.bot + this.state.params_id + "/trade-settings",
            data: values,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {

            if (ress.data && ress.data.error === 200) {
                toast.success(ress.data.message);
                self.getData();
            } else {
                if (ress.data.validation && ress.data.validation.length !== 0) {
                    _.forEach(ress.data.validation, function (value) {
                        if (value[0]) {
                            toast.error(value[0]);
                        }
                    });
                } else {
                    toast.error(ress.data.message);
                }
            }
            self.setState({ loading: false });
        }).catch(function (e) {
            //toast.error(e.message);
            self.setState({ loading: false });
        });
    };

    render() {
        return (
            <SubLayout data={{ "title": strings.trade_settings }}>
                {this.state.is_load ?
                    <Form
                        className='offset-md-4 col-md-4 trad_bot'
                        name="sign-in"
                        onFinish={(data) => this.onFinish(data)}
                        initialValues={{
                            first_buy_amount: (this.state.data && this.state.data.first_buy_amount) ? this.state.data.first_buy_amount : 0,
                            margin_call_limit: (this.state.data && this.state.data.margin_call_limit) ? this.state.data.margin_call_limit : 0,
                            take_profit_ratio: (this.state.data && this.state.data.take_profit_ratio) ? this.state.data.take_profit_ratio : 0,
                            take_profit_callback: (this.state.data && this.state.data.take_profit_callback) ? this.state.data.take_profit_callback : 0,
                            buy_in_call_back: (this.state.data && this.state.data.buy_in_call_back) ? this.state.data.buy_in_call_back : 0,
                            entry_price: (this.state.data && this.state.data.entry_price) ? this.state.data.entry_price : null,
                        }}
                    >
                        <div className="list_item" style={{ padding: 0, paddingBlock: 10 }}>
                            <div className='row' style={{ margin: 10, marginTop: 20 }}>
                                <div className='col-8' style={{ marginTop: 0 }}>
                                    <label className='' style={{ color: "#FFFFFF", fontSize: 14 }}>{strings.first_buy_in_amount}</label>
                                </div>
                                <div className='col-4 main_content'>
                                    <Form.Item
                                        name="first_buy_amount"
                                    >
                                        <Input className="form-control rounded-xs" />
                                    </Form.Item>
                                    <label className='' style={{ color: "#FFFFFF", fontSize: 14, marginLeft: 5 }}> {strings.usdt}</label>
                                </div>
                            </div>
                            <div className='boder_' />
                            <div className='row' style={{ margin: 10 }}>
                                <div className='col-8' style={{ marginTop: 0 }}>
                                    <label className='' style={{ color: "#FFFFFF", fontSize: 14 }}>{strings.open_position_doubled}</label>
                                </div>
                                <div className='col-4' style={{ marginTop: 0, textAlign: "right" }}>
                                    <Switch checked={this.state.checked} onClick={() => this.setState({ "checked": !this.state.checked })} />
                                </div>
                            </div>
                        </div>
                        <div className="list_item" style={{ padding: 0, paddingBlock: 10 }}>
                            <div className='row' style={{ margin: 10, marginTop: 20 }}>
                                <div className='col-8' style={{ marginTop: 0 }}>
                                    <label className='' style={{ color: "#FFFFFF", fontSize: 14 }}>{strings.margin_call_limit}</label>
                                </div>
                                <div className='col-4 main_content'>
                                    <Form.Item
                                        name="margin_call_limit"
                                    >
                                        <Input className="form-control rounded-xs" />
                                    </Form.Item>
                                    <label className='' style={{ color: "#FFFFFF", fontSize: 14, marginLeft: 18 }}></label>
                                </div>
                            </div>
                            <div className='boder_' />
                            <div className='row' style={{ margin: 10 }}>
                                <div className='col-8' style={{ marginTop: 0 }}>
                                    <label className='' style={{ color: "#FFFFFF", fontSize: 14 }}>{strings.take_profit_ratio}</label>
                                </div>
                                <div className='col-4 main_content'>
                                    <Form.Item
                                        name="take_profit_ratio"
                                    >
                                        <Input className="form-control rounded-xs" />
                                    </Form.Item>
                                    <label className='' style={{ color: "#FFFFFF", fontSize: 14, marginLeft: 5 }}> %</label>
                                </div>
                            </div>
                            <div className='boder_' />
                            <div className='row' style={{ margin: 10 }}>
                                <div className='col-8' style={{ marginTop: 0 }}>
                                    <label className='' style={{ color: "#FFFFFF", fontSize: 14 }}>{strings.take_profit_call_back}</label>
                                </div>
                                <div className='col-4 main_content'>
                                    <Form.Item
                                        name="take_profit_callback"
                                    >
                                        <Input className="form-control rounded-xs" />
                                    </Form.Item>
                                    <label className='' style={{ color: "#FFFFFF", fontSize: 14, marginLeft: 5 }}> %</label>
                                </div>
                            </div>
                            <div className='boder_' />
                            <div className='row' style={{ margin: 10 }}>
                                <div className='col-8' style={{ marginTop: 0 }}>
                                    <label className='' style={{ color: "#FFFFFF", fontSize: 14 }}>{strings.buy_in_callback}</label>
                                </div>
                                <div className='col-4 main_content'>
                                    <Form.Item
                                        name="buy_in_call_back"
                                    >
                                        <Input className="form-control rounded-xs" />
                                    </Form.Item>
                                    <label className='' style={{ color: "#FFFFFF", fontSize: 14, marginLeft: 5 }}> %</label>
                                </div>
                            </div>
                        </div>
                        <div className="list_item" style={{ padding: 0, paddingBlock: 10 }}>
                            <div className='row' style={{ margin: 10, marginTop: 20 }}>
                                <div className='col-8' style={{ marginTop: 0 }}>
                                    <label className='' style={{ color: "#FFFFFF", fontSize: 14 }}>{strings.entry_price}</label>
                                </div>
                                <div className='col-4 main_content'>
                                    <Form.Item
                                        name="entry_price"
                                    >
                                        <Input className="form-control rounded-xs" placeholder={strings.Market_Price} style={{ width: 100 }} />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        <div className="list_item" style={{ padding: 0, paddingBlock: 10 }}>
                            <Link to={{ pathname: `/margin-configuration/${this.state.params_id}` }} className='row' style={{ margin: 10 }}>
                                <div className='col-8' style={{ marginTop: 0 }}>
                                    <label className='' style={{ color: "#FFFFFF", fontSize: 14 }}>{strings.margin_configuration}</label>
                                </div>
                                <div className='col-4' style={{ marginTop: 0, textAlign: "right" }}>
                                    <img src={ArrowForWord} alt="" style={{ marginLeft: 20 }} />
                                </div>
                            </Link>
                        </div>

                        <div style={{ padding: 0, margin: 10 }}>
                            <Form.Item>
                                <Button
                                    loading={this.state.loading}
                                    htmlType="submit"
                                    className='btn rounded-sm btn-m login_button text-uppercase font-700 mt-4 mb-3 btn-full '
                                    style={{ opacity: this.state.loading ? 0.7 : 1, fontSize: 14, paddingBlock: 10, background: '#5CBC7C', color: "#FFF", width: "100%", borderRadius: 10 }}
                                >
                                    {this.state.loading ? strings.loading : strings.save}
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                    : null}
            </SubLayout >
        );
    }
}

export default SettingScreen;