import React from 'react';
import { Link } from 'react-router-dom';
import SubLayout from '../../../components/Layout/SubLayout.js';
import strings from "../../../components/Language/index.js";

import ArrowForWord from './../../../assets/images/icons/arrow_forword.svg';

import Bitcoin from './../../../assets/images/icons/bitcoin.svg';
import Question from './../../../assets/images/icons/question.svg';

import Cycle from './../../../assets/images/icons/trail-sign-outline.svg';
import OneShot from './../../../assets/images/icons/one_shot.svg';
import Sell from './../../../assets/images/icons/sell.svg';
import Buy from './../../../assets/images/icons/buy.svg';

import "./../tradebot.scss";

import { toast } from 'react-toastify';
import axios from 'axios';
import { api } from '../../../components/config/api.js';
import { getToken, storeUserData } from '../../../components/Util/index.js';
import _ from 'lodash';

import $ from 'jquery';
import confirm from 'jquery-confirm'; // Not remove this is needed

import { Button } from 'antd';

import { io } from "socket.io-client";

class BotScreen extends React.Component {
	constructor(props) {
		super(props);
		var params_id = this.props.match.params.id;
		this.state = {
			loading: false,
			params_id: params_id,
			tabValue: "Binance",
			access_token: "",
			pair_name: "",
			is_load: false,
			type: 0,
			qty: 10,
			data: [],
			user_data: [],
			botLiveData: [],
			average_: 0,
			get_live_bot: false,
			is_calculate: false,
		}
		this.handleChange = this.handleChange.bind(this);

	}

	async componentDidMount() {
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			setTimeout(() => {
				this.setState({
					access_token: token
				});
				this.getUserData();
				this.getData();
			});

			try {
				var self = this;
				var socket = io.connect('https://price.benchmarkbot.io');
				socket.on('connect', function (data) { });
				socket.on('price', function (data) {
					self.setState({ botLiveData: data, get_live_bot: true, })
				});
			}
			catch (e) { }
		}
	}

	notActivte() {
		toast.warning(strings.your_account_not_activated_now);
	}

	handleChange(event, newValue) {
		const self = this;
		self.setState({ tabValue: newValue });
	};

	getUserData() {
		var self = this;

		axios({
			method: 'GET',
			url: api.profile_show,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				self.setState({ user_data: ress.data.data });
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
		}).catch(function (e) {
			//toast.error(e.message);
		});
	};

	getData() {
		var self = this;
		if (self.state.access_token) {

			axios({
				method: 'GET',
				url: api.bot + this.state.params_id + "/detail",
				headers: {
					'Authorization': 'Bearer ' + self.state.access_token,
					'Content-Type': 'application/json'
				}
			}).then(function (ress) {
				if (ress.data && ress.data.error === 200) {
					self.setState({
						data: ress.data.data,
						pair_name: (ress.data.data && ress.data.data.pair && ress.data.data.pair.name) ? ress.data.data.pair.name : '',
						type: (ress.data.data && ress.data.data.type) ? 1 : 0,
						average_: (ress.data.data.total_usdt_qty && ress.data.data.total_coin_qty) ? (ress.data.data.total_usdt_qty / ress.data.data.total_coin_qty) : 0,
						is_calculate: (ress.data.data.total_coin_qty > 0) ? true : false,
						is_load: true,
					});
				} else {
					if (ress.data.validation && ress.data.validation.length !== 0) {
						_.forEach(ress.data.validation, function (value) {
							if (value[0]) {
								toast.error(value[0]);
							}
						});
					} else {
						toast.error(ress.data.message);
					}
				}
			}).catch(function (e) {
				//toast.error(e.message);
			});
		}
	};

	updateStatus() {
		var self = this;
		var api_url = '';
		if (self.state.data.status) {
			api_url = api.bot + self.state.data._id + "/stop-bot";
		} else {
			api_url = api.bot + self.state.data._id + "/start-bot";
		}

		if (self.state.access_token && api_url) {
			self.setState({ loading: true });
			axios({
				method: 'GET',
				url: api_url,
				headers: {
					'Authorization': 'Bearer ' + self.state.access_token,
					'Content-Type': 'application/json'
				}
			}).then(function (ress) {
				if (ress.data && ress.data.error === 200) {
					toast.success(ress.data.message);
					self.getData();
				} else {
					if (ress.data.validation && ress.data.validation.length !== 0) {
						_.forEach(ress.data.validation, function (value) {
							if (value[0]) {
								toast.error(value[0]);
							}
						});
					} else {
						toast.error(ress.data.message);
					}
				}
				self.setState({ loading: false });
			}).catch(function (e) {
				//toast.error(e.message);
				self.setState({ loading: false });
			});
		}
	};

	updateType(type) {
		var self = this;

		if (!self.state.data._id) {
			toast.error("ID not found!");
			return false;
		}

		if (self.state.access_token) {
			self.setState({ loading: false });
			axios({
				method: 'POST',
				url: api.bot + self.state.data._id + "/change-type",
				data: { type: type },
				headers: {
					'Authorization': 'Bearer ' + self.state.access_token,
					'Content-Type': 'application/json'
				}
			}).then(function (ress) {
				if (ress.data && ress.data.error === 200) {
					toast.success(ress.data.message);
					self.getData();
				} else {
					if (ress.data.validation && ress.data.validation.length !== 0) {
						_.forEach(ress.data.validation, function (value) {
							if (value[0]) {
								toast.error(value[0]);
							}
						});
					} else {
						toast.error(ress.data.message);
					}
				}
			}).catch(function (e) {
				//toast.error(e.message);
			});
		}
	};

	sellOrder() {
		var self = this;

		if (!self.state.data._id) {
			toast.error(strings.id_not_found);
			return false;
		}

		if (self.state.access_token) {
			axios({
				method: 'POST',
				url: api.bot + self.state.data._id + "/sell-order",
				data: {},
				headers: {
					'Authorization': 'Bearer ' + self.state.access_token,
					'Content-Type': 'application/json'
				}
			}).then(function (ress) {
				if (ress.data && ress.data.error === 200) {
					toast.success(ress.data.message);
					self.getData();
				} else {
					if (ress.data.validation && ress.data.validation.length !== 0) {
						_.forEach(ress.data.validation, function (value) {
							if (value[0]) {
								toast.error(value[0]);
							}
						});
					} else {
						toast.error(ress.data.message);
					}
				}
			}).catch(function (e) {
				//toast.error(e.message);
			});
		}
	};

	buyOrder(usdt) {
		var self = this;

		if (!self.state.data._id) {
			toast.error(strings.id_not_found);
			return false;
		}

		if (self.state.access_token) {
			axios({
				method: 'POST',
				url: api.bot + self.state.data._id + "/buy-order",
				data: {
					qty: usdt
				},
				headers: {
					'Authorization': 'Bearer ' + self.state.access_token,
					'Content-Type': 'application/json'
				}
			}).then(function (ress) {
				if (ress.data && ress.data.error === 200) {
					toast.success(ress.data.message);
					self.getData();
				} else {
					if (ress.data.validation && ress.data.validation.length !== 0) {
						_.forEach(ress.data.validation, function (value) {
							if (value[0]) {
								toast.error(value[0]);
							}
						});
					} else {
						toast.error(ress.data.message);
					}
				}
			}).catch(function (e) {
				//toast.error(e.message);
			});
		}
	};

	buyAlert() {
		var self = this;
		$.confirm({
			title: strings.are_you_sure_BUY,
			theme: 'dark',
			content: '' +
				'<form action="" class="formName">' +
				'<div class="form-group">' +
				'<label>USDT</label>' +
				'<input type="number" placeholder="' + strings.enter_USDT + '" class="buy_usdt form-control" required />' +
				'</div>' +
				'</form>',
			buttons: {
				formSubmit: {
					text: strings.BUY,
					btnClass: 'btn-success',
					action: function () {
						var buy_usdt = this.$content.find('.buy_usdt').val();
						if (!buy_usdt) {
							toast.error(strings.USDT_required);
							return false;
						} else {
							self.buyOrder(buy_usdt);
						}
					}
				},
				cancel: function () {
					//close
				},
			},
			onContentReady: function () {
				// bind to events
				var jc = this;
				this.$content.find('form').on('submit', function (e) {
					// if the user submits the form by pressing enter in the field.
					e.preventDefault();
					jc.$$formSubmit.trigger('click'); // reference the button and click it
				});
			}
		});
	};

	sellAlert() {
		var self = this;
		$.confirm({
			title: strings.confirm + '!',
			theme: 'dark',
			content: strings.are_you_sure_SELL,
			buttons: {
				somethingElse: {
					text: strings.SELL,
					btnClass: 'btn-success',
					action: function () {
						self.sellOrder();
					}
				},
				cancel: function () {
					//close
				},
			}
		});
	};

	render() {

		return (
			<>
				{
					this.state.is_load ?
						<SubLayout data={{ "title": this.state.pair_name + " " + strings.DCA_Bot }}>
							{this.state.data ?
								<>
									<div className="list_item">
										<div className="row">
											<div className='col-8 flex-center'>
												<img className="image_icon" src={(this.state.data && this.state.data.pair && this.state.data.pair.icon_url) ? this.state.data.pair.icon_url : Bitcoin} alt="" width={25} height={25} />
												<div className='width-auto' style={{ marginLeft: 10 }}>
													<span className=''>{(this.state.data && this.state.data.pair && this.state.data.pair.name) ? this.state.data.pair.name : ''}</span>
												</div>
											</div>
											<div className='col-4 avg_label'>
												<img className="image_icon" style={{ marginRight: 10 }} src={Question} alt="" width={20} height={20} />
											</div>
										</div>
										<div className="row list_details gray_color">
											<div className='flex-center col-12'>
												<div className='flex-center' style={{ marginTop: 10, marginRight: 10 }}>
													<div className='cycle '>
														<label className=''>{(this.state.type) ? strings.cycle : strings.one_shot}</label>
													</div>
												</div>
											</div>
											<div className='row row_list'>
												<div className='col-4 text-center' style={{ marginTop: 0, padding: 0 }}>
													<div className=''>
														<label className='' style={{ color: "#FFFFFF", fontSize: 16 }}>{(this.state.data && this.state.data.total_usdt_qty) ? Number.parseFloat(this.state.data.total_usdt_qty).toFixed(5) : 0}</label>
													</div>
													<div className=''>
														<label className='' style={{ color: "#FFFFFF", fontSize: 12 }}>{strings.position_amount} ({strings.usdt})</label>
													</div>
												</div>
												<div className='col-4 text-center' style={{ marginTop: 0, padding: 0 }}>
													<div className=''>
														<label className='' style={{ color: "#FFFFFF", fontSize: 16 }}>{(this.state.average_) ? Number.parseFloat(this.state.average_).toFixed(5) : 0}</label>
													</div>
													<div className=''>
														<label className='' style={{ color: "#FFFFFF", fontSize: 12 }}>{strings.avg_price} ({strings.usdt})</label>
													</div>
												</div>
												<div className='col-4 text-center' style={{ marginTop: 0, padding: 0 }}>
													<div className=''>
														<label className='' style={{ color: "#FFFFFF", fontSize: 16 }}>{(this.state.data && this.state.data.total_buy_counter && this.state.data.total_buy_counter > 0) ? (this.state.data.total_buy_counter - 1) : 0}</label>
													</div>
													<div className=''>
														<label className='' style={{ color: "#FFFFFF", fontSize: 12 }}>{strings.numbers_of_call_margin}</label>
													</div>
												</div>
											</div>
											<div className='row row_list'>
												<div className='col-4 text-center' style={{ marginTop: 0, padding: 0 }}>
													<div className=''>
														<label className='' style={{ color: "#FFFFFF", fontSize: 16 }}>{(this.state.data && this.state.data.total_coin_qty) ? Number.parseFloat(this.state.data.total_coin_qty).toFixed(5) : 0}</label>
													</div>
													<div className=''>
														<label className='' style={{ color: "#FFFFFF", fontSize: 12 }}>{strings.position_quantity}</label>
													</div>
												</div>
												<div className='col-4 text-center' style={{ marginTop: 0, padding: 0 }}>
													<div className=''>
														<label className='' style={{ color: "#FFFFFF", fontSize: 16 }}>
															{(this.state.data && this.state.data.pair && this.state.data.pair.name && this.state.botLiveData[this.state.data.pair.name]) ?
																Number.parseFloat(this.state.botLiveData[this.state.data.pair.name]).toFixed(5)
																: "0.00"}
														</label>
													</div>
													<div className=''>
														<label className='' style={{ color: "#FFFFFF", fontSize: 12 }}>{strings.current_price} ({strings.usdt})</label>
													</div>
												</div>
												<div className='col-4 text-center' style={{ marginTop: 0, padding: 0 }}>
													<div className=''>
														<label className='' style={{ color: "#FFFFFF", fontSize: 16 }}>
															{(this.state.is_calculate && this.state.get_live_bot) ?
																Number.parseFloat((this.state.botLiveData[this.state.data.pair.name] - this.state.average_) / 100).toFixed(5)
																: "0"}%
														</label>
													</div>
													<div className=''>
														<label className='' style={{ color: "#FFFFFF", fontSize: 12 }}>{strings.return_rate}</label>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="list_item" style={{ padding: 0, paddingBlock: 10 }}>
										<div className='row row_list'>
											<div onClick={() => this.updateType(this.state.type ? 0 : 1)} className='col-4 text-center' style={{ marginTop: 0, padding: 0, cursor: "pointer" }}>
												<div className=''>
													{this.state.type ?
														<img src={Cycle} width={30} alt="" height={30} />
														:
														<img src={OneShot} width={30} alt="" height={30} />
													}
												</div>
												<div className='' style={{ marginTop: 10 }}>
													<label className='' style={{ color: "#FFFFFF", fontSize: 12, textTransform: "uppercase" }}>{this.state.type ? strings.cycle : strings.one_shot}</label>
												</div>
											</div>
											<div onClick={() => this.sellAlert()} className='col-4 text-center' style={{ marginTop: 0, padding: 0, cursor: "pointer" }}>
												<div className=''>
													<img src={Sell} width={30} alt="" height={30} />
												</div>
												<div className='' style={{ marginTop: 10 }}>
													<label className='' style={{ color: "#FFFFFF", fontSize: 12 }}>{strings.SELL}</label>
												</div>
											</div>
											<div onClick={() => this.buyAlert()} className='col-4 text-center' style={{ marginTop: 0, padding: 0, cursor: "pointer" }}>
												<div className=''>
													<img src={Buy} width={30} alt="" height={30} />
												</div>
												<div className='' style={{ marginTop: 10 }}>
													<label className='' style={{ color: "#FFFFFF", fontSize: 12, textTransform: "capitalize" }}>{strings.BUY}</label>
												</div>
											</div>
										</div>
										{/* <div className='row row_list' style={{ marginTop: 20 }}>
									<div className='col-4 text-center' style={{ marginTop: 0, padding: 0 }}>
										<div className=''>
											<img src={StopMargin} alt="" width={30} height={30} />
										</div>
										<div className='' style={{ marginTop: 10 }}>
											<label className='' style={{ color: "#FFFFFF", fontSize: 12 }}>Stop Margin Call</label>
										</div>
									</div>
									<div className='col-4 text-center' style={{ marginTop: 0, padding: 0 }}>
										<div className=''>
											<img src={StrategyMode} alt="" width={30} height={30} />
										</div>
										<div className='' style={{ marginTop: 10 }}>
											<label className='' style={{ color: "#FFFFFF", fontSize: 12 }}>Strategy Mode</label>
										</div>
									</div>
								</div> */}
									</div>
									{/* <div className="list_item" style={{ padding: 0, paddingBlock: 10 }}>
										<div className='row row_list'>
											<div className='col-8' style={{ marginTop: 0 }}>
												<label className='' style={{ color: "#FFFFFF", fontSize: 12 }}>{strings.first_preset_buy_in_price}</label>
											</div>
											<div className='col-4' style={{ marginTop: 0, textAlign: "right" }}>
												<img src={ArrowForWord} alt="" style={{ marginLeft: 20 }} />
											</div>
										</div>
									</div> */}
									<div className="list_item" style={{ padding: 0, paddingBlock: 10 }}>
										<div className='row row_list'>
											<div className='col-6' style={{ marginTop: 0 }}>
												<label className='' style={{ color: "#FFFFFF", fontSize: 12 }}>{strings.first_buy_amount} {(this.state.data && this.state.data.first_buy_amount) ? this.state.data.first_buy_amount : 0}</label>
											</div>
											<div className='col-6' style={{ marginTop: 0, textAlign: "right" }}>
												<label className='' style={{ color: "#FFFFFF", fontSize: 12 }}>{strings.margin_call_limit} {(this.state.data && this.state.data.margin_call_limit) ? this.state.data.margin_call_limit : 7}</label>
											</div>
										</div>
										<div className='row row_list'>
											<div className='col-6' style={{ marginTop: 0 }}>
												<label className='' style={{ color: "#FFFFFF", fontSize: 12 }}>{strings.take_profit_ratio} {(this.state.data && this.state.data.take_profit_ratio) ? this.state.data.take_profit_ratio : 0}%</label>
											</div>
											<div className='col-6' style={{ marginTop: 0, textAlign: "right" }}>
												<label className='' style={{ color: "#FFFFFF", fontSize: 12 }}>{strings.take_profit_call_back} {(this.state.data && this.state.data.take_profit_callback) ? this.state.data.take_profit_callback : 0}%</label>
											</div>
										</div>
										<div className='row row_list'>
											<div className='col-6' style={{ marginTop: 0 }}>
												<label className='' style={{ color: "#FFFFFF", fontSize: 12 }}>{strings.buy_in_callback} {(this.state.data && this.state.data.buy_in_call_back) ? this.state.data.buy_in_call_back : 0}%</label>
											</div>
										</div>
									</div>

									<div className='flex-center' style={{ marginBottom: 15, width: "95%" }}>
										<Link to={{ pathname: `/trade-settings/${this.state.params_id}` }} className='col-6' style={{ marginTop: 0, textAlign: "center", background: '#446CE3', padding: 15, cursor: "pointer" }}>
											<label className='' style={{ color: "#FFFFFF", fontSize: 16 }}>{strings.trade_setting}</label>
										</Link>
										<Button
											loading={this.state.loading}
											className='col-6'
											onClick={() => (this.state.user_data && this.state.user_data.status) ? this.updateStatus() : this.notActivte()}
											style={{ opacity: (this.state.loading) ? 0.7 : 1, marginTop: 0, textAlign: "center", background: (this.state.data && this.state.data.status) ? "#FC8383" : "#5CBC7C", padding: 15, cursor: "pointer", borderWidth: 0 }}
										>
											<label className='' style={{ color: "#FFFFFF", fontSize: 16 }}>
												{(this.state.loading) ? strings.loading : (this.state.data && this.state.data.status) ? strings.stop : strings.start}
											</label>
										</Button>
									</div>
								</>
								:
								<h5 className='text-danger text-center mt-2'>{strings.bot_not_found}</h5>
							}
						</SubLayout >
						: null
				}
			</>
		);
	}
}

export default BotScreen;