const en = {

    ctcbot: "ctcbot",

    // Menu
    Home: "Home",
    Quatitative: "Quatitative",
    Community: "Community",
    Notifications: "Notifications",
    Profile: "Profile",

    api_bind: "API Bind",
    invite_friend: "Invite Friend",
    wallet: "Wallet",
    rewards: "Rewards",

    //Login
    login: "Login",
    sign_in: "Sign In",
    register_here: "Register Here",
    not_register_yet: "Not Register yet?",
    email: "Email",
    email_enter: "Enter E-Mail",
    email_address: "E-Mail Address",
    email_address_enter: "Enter E-Mail Address",
    forgot_password: "Forgot Password",
    email_input: "Please input your E-mail!",
    email_not_valid: "The input is not valid E-mail!",
    password: "Password",
    password_enter: "Enter Password",
    password_input: "Please input your password!",
    retype_password: "Retype Password",
    retype_password_enter: "Enter retype password",
    retype_password_input: "Please input your retype password!",
    retype_password_not_match: "The two passwords that you entered do not match!",
    
    // Register
    register: "Register",
    sign_up: "Sign Up",
    please_input_your_username: "Please input your Username!",
    enter_username: "Enter Username",
    invitation_code: "Invitation Code",
    invitation_code_enter: "Enter invitation code",
    invitation_code_required: "Please input your invitation code!",
    register_now: "Register Now",
    congratulations: "Congratulations",
    register_successful: "Register Successful",

    // Common
    Coming_Soon: "Coming Soon!",
    loading: "Loading...",
    submit: "Submit",
    refresh: "Refresh",
    add: "Add",
    confirm: "Confirm",
    logout: "Logout",
    done: "Done",
    login_now: "Login Now",
    no_records_found: "No Records Found!",
    save: "Save",
    Load_More: "Load More",
    we_could_not_find: "We couldn't find",
    page: "page",
    USD: "USD",
    english: "English",
    malaysia: "Malaysia",
    chinese: "Chinese",

    // Verification Code...
    verification_code: "Verification Code",
    verification_code_enter: "Please Enter Verification Code",
    verification_code_required: "The verification code field is required.",
    verification_code_not_valid: "You enter verification code not valid! Please enter valid verification code.",
    input_your_verification_code: "Please input your verification code",
    otp_not_match: "The OTP you entered do not match!",
    send_code: "Send Code",
    get_code: "Get Code",

    profile: "Profile",
    id: "ID",
    affiliate_program: "Affiliate program",
    team: "Team",
    activate: "Activate",
    not_activated_yet: "Not Activated Yet",
    activate_profit: "Activate Profit",
    activate_profit_today: "Activate Profit Today",
    activate_profit_total: "Activate Total Profit",
    direct_quantification: "Direct Quantification",
    team_quantification: "Team Quantification",
    direct_referrals: "Direct Referrals",
    grade: "Grade",
    account: "Account",
    number_of_team: "Number of Team",
    registration_time: "Registration Time",
    reward_details: "Reward Details",
    full_details: "Full Details",
    bot_quantification: "Bot Quantification",
    profit: "Profit",
    pair: "Pair",
    description: "Description",
    profit_quantification: "Profit Quantification",
    total_profit_today: "Total Profit Today",
    total_profit_earned: "Total Profit EARNED",
    share: "Share",
    helpline: "Helpline",
    account_problem: "Account Problem",
    trading_problem: "Trading Problem",
    suggestion_opinions: "Suggestion Opinions",
    report_violation: "Report Violation",
    money_flow_problem: "Money Flow Problem",
    api_binding_issues: "API Binding Issues",
    bug_problem: "Bug Problem",
    other: "Other",
    security_centre: "Security Centre",
    my_policy_settings: "My Policy Settings",
    system_setting: "System Setting",
    change_email: "Change Email",
    new_email_enter: "Please Enter New Email",
    new_email_verification_code: "New email verification Code",
    new_email_verification_code_enter: "Enter new email verification code",
    safety_email_verification_Code: "Safety email verification Code",
    please_enter_the_email_verification_code: "Please enter the email verification code",
    change_password: "Change Password",
    enter_old_password: "Please Enter Old Password",
    input_your_old_password: "Please input your old password!",
    please_enter_password: "Please Enter Password",
    please_enter_retype_password: "Please enter retype password",
    delete_account: "Delete Account",
    delete_account_note: "Note that after the account is deleted, the information will be emptied and cannot be retrieved.",
    delete_account_following_conditions: "The account cannot be deleted under the following conditions:",
    delete_account_following_conditions_1: "- There is errors in the account.",
    delete_account_following_conditions_2: "- Sync policy has not been cancelled.",
    delete_account_following_conditions_3: "- All trading strategies are not yet over.",
    delete_account_following_conditions_4: "- API binding is not unbound.",
    profile_information: "Profile Information",
    change_avatar: "Change Avatar",
    change_profile_image: "Change Profile Image",
    name: "Name",
    username: "Username",
    my_inviter_id: "My Inviter ID",
    level: "Level",
    status: "Status",
    language: "Language",
    transaction_password: "Transaction Password",
    new_password: "New Password",
    please_enter_new_password: "Please enter new password",
    Please_input_your_new_password: "Please input your new password!",
    google_verification_code: "Google Verification Code",
    withdraw_address_management: "Withdraw Address Management",
    bank_management: "Bank Management",
    touch_id_pin: "Touch ID & PIN",
    valuation_method: "Valuation Method",
    theme_mode: "Theme Mode",
    dark_mode: "Dark Mode",
    firmware_version: "Firmware Version",
    Latest_Announcement: "Latest Announcement",
    Announcement_Detail: "Announcement Detail",
    binding: "Binding",
    percautions: "Percautions",
    see_the_instructions: "See the instructions",
    see_the_instructions_point_1: "1. Please confirm that the API permission has checked “Enabled Spot & Margin Trading”",
    see_the_instructions_point_2: "2. Please enter the correct API, please do not enter special characters.",
    see_the_instructions_point_3: "3. Trusted IP: 118.107.235.48",
    access_key: "Access Key",
    access_key_enter: "Please Enter Access Key",
    secret_key: "Secret Key",
    secret_key_enter: "Please Enter Secret Key",
    access_key_required: "The access key field is required.",
    secret_key_required: "The secret key field is required.",
    bound: "Bound",
    unbound: "Unbound",
    unbind: "Unbind",
    bind_now: "Bind Now",
    member_center: "Member Center",
    current_level_lower_info: "The current Level is lower than this level",
    level_membership_info: "This level membership rights not available please upgrad your level!",
    B0: "B0",
    no_record_found: "No Record Found",
    cycle: "Cycle",
    one_shot: "One-Shot",
    quantity: "Quantity",
    latest_price: "Latest Price",
    floating_profit: "Floating Profit",
    increase: "Increase",
    code_copy: "Code Copy",
    code_copy_seccessfully: "Code copy seccessfully!",
    copy_link: "Copy Link",
    link_copy_seccessfully: "Link copy seccessfully!",
    my_invitation_code: "My invitation code",
    my_policy_setting: "My Policy Setting",
    no_notifications: "No Notifications",
    logs: "Logs",
    no_logs: "No Log Found",
    market: "Market",
    amount: "Amount",
    price: "Price",
    fee: "Fee",
    total: "Total",
    your_account_not_activated_now: "Your account is currently not activated please first activate your account!",
    id_not_found: "ID not found!",
    BUY: "BUY",
    are_you_sure_BUY: "Are you sure BUY?",
    SELL: "SELL",
    are_you_sure_SELL: "Are you sure SELL?",
    usdt: "USDT",
    enter_USDT: "Enter USDT",
    USDT_required: "USDT is required!",
    trade_bot: "Trade Bot",
    position_amount: "Position Amount",
    avg_price: "Avg Price",
    numbers_of_call_margin: "Numbers of Call Margin",
    position_quantity: "Position Quantity",
    current_price: "Current Price",
    return_rate: "Return Rate",
    first_preset_buy_in_price: "First Preset Buy In Price",
    first_buy_amount: "First Buy Amount",
    margin_call_limit: "Margin Call Limit",
    take_profit_ratio: "Take Profit Ratio",
    take_profit_call_back: "Take Profit Callback",
    buy_in_callback: "Buy In Callback",
    trade_setting: "Trade Setting",
    stop: "Stop",
    start: "Start",
    bot_not_found: "Bot detail not found!",
    margin_configuration: "Margin Configuration",
    Margin_call_Drop: "Margin call Drop",
    Multiple_Of_Times: "Multiple Of Times",
    trade_settings: "Trade Settings",
    first_buy_in_amount: "First Buy In Amount",
    open_position_doubled: "Open Position Doubled",
    wallet_address_not_foud: "Wallet address not foud!",
    transfer: "Transfer",
    transfer_details: "Transfer Details",
    confirm_transfer: "Confirm Transfer",
    deposit: "Deposit",
    withdraw: "Withdraw",
    deposit_details: "Deposit Details",
    chain_name: "Chain Name",
    deposit_address: "Deposit address",
    USDT_deposit_address: "USDT Deposit address",
    address_can_only_receive_USDT: "This address can only receive USDT",
    code_link: "Code Link",
    risk_disclaimer: "Risk Disclaimer",
    deposit_risk_disclaimer_point_1: "1. Please do not deposit any non-USDT assets to the above address, otherwise the assets will not be retrieved.",
    deposit_risk_disclaimer_point_2: "2. Deposit non-USDT assets will not be entertained.",
    deposit_risk_disclaimer_point_3: "3. Inactive accounts cannot be withdrawn.",
    withdrawal_risk_disclaimer_point_1: "1. The minimum amount of single withdrawal is 10 USDT",
    withdrawal_risk_disclaimer_point_2: "2. Withdrawal will be proceed within 24hours.",
    withdrawal_risk_disclaimer_point_3: "3. Please provide correct TRC20 USDT address to avoid any losses of your assets.",
    URI_not_found: "URI not found!",
    TXID: "TXID",
    Total_Assets_Converted_USDT: "Total Assets Converted USDT",
    history_record: "History Record",
    the_recipient_wallet_username_field_is_required: "The recipient wallet username field is required.",
    the_amount_field_is_required: "The amount field is required.",
    the_verification_code_field_is_required: "The verification code field is required.",
    verification_code_not_valid_enter_valid: "You enter verification code not valid! Please enter valid verification code.",
    Receipient_UserID: "Receipient UserID",
    transfer_quantity: "Transfer quantity",
    All: "All",
    Available: "Available",
    Transaction_Fees: "Transaction Fees",
    the_address_field_is_required: "The address field is required.",
    withdrawal: "Withdrawal",
    withdrawal_details: "Withdrawal Details",
    Withdraw_details: "Withdraw Details",
    withdrawal_address: "Withdrawal address",
    withdrawal_address_enter: "Please Enter Withdrawal Address",
    withdrawal_quantity: "Withdrawal quantity",
    minimum_withdraw_amount: "Minimum Withdraw Amount",
    receive_amount: "Receive Amount",
    confirm_withdrawal: "Confirm Withdrawal",
    Scan_QR_Code: "Scan QR Code",
    Add_Bank: "Add Bank",
    Add_Bank_input: "Please input your bank name!",
    Add_Bank_enter: "Please enter bank name",
    Account_Holder: "Account Holder",
    Account_Holder_input: "Please input your bank account holder!",
    Account_Holder_enter: "Please enter bank account holder",
    Account_Number: "Account Number",
    Account_Number_input: "Please input your bank account number!",
    Account_Number_enter: "Please enter bank account number",
    Branch: "Branch",
    Branch_input: "Please input your bank branch!",
    Branch_enter: "Please enter bank branch",
    City: "City",
    City_input: "Please input your bank city!",
    City_enter: "Please enter bank city",
    the_wallet_address_field_is_required: "The wallet address field is required.",
    Add_Wallet: "Add Wallet",
    Currency: "Currency",
    Mainnet: "Mainnet",
    Address: "Address",
    Wallet_Address: "Wallet Address",
    Wallet_Label: "Wallet Label",
    Bank_Management: "Bank Management",
    Wallet_Management: "Wallet Management",
    WhiteList: "WhiteList",
    Trading_Profit: "Trading Profit",
    Chart: "Chart",
    Chart_detail: "Chart Detail",
    fuel_balance_is_insufficient: "Fuel Balance is insufficient, required minimum 20usdt in your wallet",
    view_details: "View Details",
    view_monthly_report: "View Monthly Report",
    total_daily_trading_profit: "TOTAL DAILY TRADING PROFIT",
    spot_trading: "SPOT TRADING",
    Trading_Details: "Trading Details",
    bot_reward_details: "BOT Reward Details",
    profit_details: "Profit Details",
    LiveChatSupport: "Live Chat Support",
    need_to_active_account: "Need to active the account first.",
    whole_position: "Whole position",
    distributed_and_take_profit_allocation: "Distributed and Take Profit Allocation",
    sub_position: "Sub-position",
    sub_position_take_profit_callback: "Sub-position take-profit callback",
    strategy_period: "Strategy Period",
    order_list: "Order List",
    sell_all: "SELL ALL",
    are_you_sure_SELL_all: "Are you sure SELL all?",
    total_order_number: "Total Order Number",
    layer: "Layer",
    layer_call_limit: "Layer Call Limitt",
    all_layer_buy_in_amount: "All Layer Buy In Amount",
    all_layer_take_profit_ratio: "All Layer Take Profit Ratio",
    all_layer_take_profit_call_back: "All Layer Take Profit Callback",
    all_layer_buy_in_callback: "All Layer Buy In Callback",
    all_layer_margin_call_back: "All Layer Margin Callback",
    are_you_sure_you: "are you sure you",
    order_: "order?",
    CONNECT_TO_ACCESS_HOLDER_AREA: "CONNECT TO ACCESS HOLDER-AREA",
    CONNECT_WALLET: "CONNECT WALLET",
    terms_conditions: "Terms & Conditions",
    terms_conditions_1: "1. Owner required to have at least one ctcbot NFT in the wallet.",
    terms_conditions_2: "2. This is a read-only connection, It will not trigger any blockchain transaction or incur any charge fee.",
    terms_conditions_3: "3. Do not share your Private key. We will never ask your Private key.",
    Copy_IP_address: "Copy IP address",
    Copy_IP_address_msg: "IP address Copied!",
    gas_fees: "Gas Fees",
    gas_fees_details: "Gas Fees Details",
    convert: "Convert",
    confirm_conver: "Confirm Convert",
    convert_quantity: "Convert Quantity",
    copy_id: "ID Copied!",
    all_layer_buy_in_ratio: "All Layer Buy In Ratio",
    Order_Amount: "Order Amount",
    Max_trade: "Max Trade",
    BOLL_Bot: "BOLL Bot",
    DCA_Bot: "DCA Bot",
    entry_price: "Entry Price",
    Market_Price: "Market Price",
    Data_not_found: "Data not found!",
};
export default en;